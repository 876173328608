import yellowEclipse from '../../../images/yelloEcplise.png'
import blueEclipse from '../../../images/blueEcplise.png'
import cyanEclipse from '../../../images/cyanEclipse.png'
import howDoesItIcon1 from '../../../images/how its works Icon 1.svg'
import howDoesItIcon2 from '../../../images/how it works Icon 2.svg'
import howdoesItIcon3 from '../../../images/how it works  Icon 3.svg'
import howdoes1 from '../../../images/Mobile 1.png'
import howdoes2 from '../../../images/Mobile 2.png'
import howdoes3 from '../../../images/Mobile 3.png'
export default  [
    {
    title:"You integrate the Ceplr API",
    description:"Connect using simple code to cutting edge infrastructure based on complex specifications.",
    image:cyanEclipse,
    id:1,
    picture:howDoesItIcon1,
    slidePicture:howdoes1
},
    {
    title:"We get your customer's consent ",
    description:"Our highly simplified interface allows users to quickly provide OTP-based consent to sharing their data.    ",
    // image:second,
    id:2,
    picture:howDoesItIcon2,
    image:blueEclipse,
    slidePicture:howdoes2

},
    {
    title:"You receive the required data ",
    description:"Ceplr sends you data in a standard format—decrypted and ready to consume. ",
    // image:third,
    id:3,
    picture:howdoesItIcon3,
    image:yellowEclipse,
    slidePicture:howdoes3

},
//     {
//     title:"jsp",
//     description:"hello my name is then ",
//     image:fourth
// },


]