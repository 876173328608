export function BaseUrl() {
  if (process.env.REACT_APP_STAGE == "prod") {
    return "api";
  } else if (process.env.REACT_APP_STAGE == "dev") {
    return "api-demo";
  } else {
    return "api-test";
  }
}

export function dashboardUrl() {
  if (process.env.REACT_APP_STAGE == "prod") {
    return "https://dashboard.ceplr.com/consent-flow";
  } else if (process.env.REACT_APP_STAGE == "dev") {
    return "https://dashboard.ceplr.com/consent-flow";
  } else {
    return "https://dashboard-me.ceplr.com/consent-flow";
  }
}

export function RedirectUrl() {
  if (process.env.REACT_APP_STAGE == "prod") {
    return "https://ceplr.com/approved";
  } else if (process.env.REACT_APP_STAGE == "dev") {
    return "https://ceplr.com/approved";
  } else {
    return "https://me.ceplr.com/approved";
  }
}

export function getDocumentLink() {
  const link = "https://documenter.getpostman.com/view/25610463/2s935kMjsg";
  return link;
}
