import React,{useState} from 'react'

import Button from '@restart/ui/esm/Button'

import {useForm} from 'react-hook-form'
import emailjs  from 'emailjs-com'
import { BaseUrl } from 'Config/BaseUrl'


//Images
import heroImage from "../../images/hero-02.svg"
import whyCeplrBg from '../../images/why ceplr BG (2).svg'
import whyCeplr from '../../images/Frame.svg'
import getStartedBg from '../../images/get started BG.svg'
import getStartedUg from '../../../../images/get started image.svg'
import privacy from '../../../../images/privacy 1.png'
import backgroundImage from '../../images/Mask Group.svg'
import blueArrow from '../../images/blueArrow.png'
import whiteArrow from '../../../../images/whiteUseArrow.svg'
import useCaseBanner from '../../images/use case banner-05-09 1.svg'
import useCase1 from '../../../../images/mainUseCase1.svg'
import useCase2 from '../../../../images/mainUseCase2.svg'
import useCase3 from '../../../../images/mainUseCase3.svg'

import  './About.css'

type profile = {
    Name: string;
    Email: string;
    Mobile: number;
  CompanyName:string;
  Message:string;
  };


function AboutUs() {

    const [result,showResult]=useState(false)
  const [submit,setSubmit ]  = useState(false);


  const Result=()=>{
    return(
      <div className="resultClass">Your message has been forwarded to our customer support team, who will contact you shortly. Thank you!</div>
    )
  }
  const ErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }
  const MobileErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }

      
    const {register,handleSubmit,formState: { errors },reset}=useForm<profile>(
      {mode:"onTouched"}
    )
    
      const onSubmit=handleSubmit(async(data:any)=>{
        setSubmit(true)
        
        // console.log(data, "data")
        const requestOptions = {
          method: 'POST',
          
          headers: { 
              // "authorization": ' xxxxxxxxxx' ,
  
            
              // "Content-Type":"application/json",
                    //  "x-api-key": "qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
                   
                     
      },
      
           body: JSON.stringify({name:data.Name,email:data.Email,companyName:data.CompanyName,mobileNumber:data.Mobile,description:data.Message}),
  
           
      };
      // console.log(requestOptions, "requestOptions")
      try{
          await fetch(`https://${BaseUrl()}.ceplr.com/sendMail`, requestOptions)
          .then(response => response.json())
          .then(res => {
            //  console.log(res,"res")
             reset()
 setSubmit(false)
 showResult(true)
              
          })
  
              // console.log('The link was clicked.');
              
          
      }catch (error) {
          console.log(error, "error");
        }
      // axios.post('/api/form').then((response:any)=>{
      //   if (response.data.status === 'success'){
      //       alert("Message Sent."); 
      //       // console.log(response,"response")
      //       reset()
      //   }else if(response.data.status === 'fail'){
      //       alert("Message failed to send.")
      //   }
      // })
      
      // reset()
      // }
      
      // ).catch(err=>console.log(err))
      })
    
     //hideResult
  // setTimeout(()=>{
  //   showResult(false)
  // },5000)


    return (
        <>
             <section id="AboutUsBanner">
        {/* <img src={backgroundImage}/> */}
        <div className=" container ">
        {/* <img src={useCaseBanner} style={{resize:"both"}}/> */}
                <div className="row  ">
                
                <h1> About Us</h1>
               
                </div>
            </div>
      
        </section>
            <section id ="About1section">
            {/* <img src={whyCeplrBg} className="whyCeplrBG"/> */}
        <div className="container">
        <div className="row align-items center ">
            <div className=" col col-sm-12 col-xl-6">
                   <div className="aboutUscontainer">


                       <div className="aboutUsHeading">
                       Who we are?   </div>
                       <div className="AboutUsTextSmall">
                       
                       Ceplr is a technology service provider in the Account Aggregator 
ecosystem, which is an implementation of the revolutionary 
Data Empowerment and Protection Act (DEPA). We believe the 
future of data lies in empowering people to claim ownership of 
their financial data and share it selectively and securely with 
the companies they choose.

                       </div>
                      
           
                   </div>
            </div>
            <div className="col col-sm-12 col-xl-6 ">
                <img src={useCase2} className="aboutUsImage"/>
                </div>
           
        </div>
        
        </div>
            </section>
           
            <section id="GetStarted">
        {/* <img src={getStartedBg} className="getStartedBg"/> */}
           <div className="container">
           <div className="row align-items center">
                    <div className="col-sm-12 col-xl-6">
                    <img src={getStartedUg} className="getstarted-Original"/>
                        {/* <p>Get Started</p> */}
                      
                       
               </div>
               <div className="col-sm-12 col-xl-6">
                  
               {window.innerWidth>920? <form id="contact-form" className="contactWeb-formContainer" onSubmit={onSubmit}>
                       <h2>Ready to get started?</h2>
                       <p>Our experts can help you with your queries or show you a demo of our products.</p>
        
        <div className="firstRowInput">
          <div className="input1">
        <input  className="contactUs-text-input1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

{errors.Name && errors.Name.type === "required" && (
  <div className="mobileError">Name is required.</div>
)}
</div>
<div className="input2">
 <input  className="contactUs-text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

{errors.CompanyName && errors.CompanyName.type === "required" && (
  <div className="mobileError">Company name is required.</div>
)}
</div>
        </div>
        <div className="firstRowInput1">
        <div className="input1">
        <input  className="contactUs-text-input1" autoCapitalize='none' placeholder="Company Email " {...register("Email",{required:"Company email is required.",
pattern:{
 value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
 message:"Please enter a valid email."

}
       })}/>

       {errors.Email && (
         <ErrorMessage message={errors.Email.message}/>        )}
         </div>

         <div className="input2">
        <input  className="contactUs-text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
       </div>
       
        </div>

        <div className="secondRowInput">
        <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />

       
        </div>

        <div >
          <div className="priv-divContact">
          <img src={privacy} className="privacy-image"/>
           
           {"  " }    Your privacy is important to us. 
          </div>
        <button className="contactUsPage-Button">
          {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}
          Submit
        </button>
        <div>{result?<Result/>:null}</div>

        </div>
      </form>:<form id="contact-form" className="contact-formContainerMobileFirst" onSubmit={onSubmit}>
                        <h2>Ready to get started?</h2>
                        <p>Our experts can help you with your queries or show you a demo of our products.</p>
         <input  className="text-Contactinput1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

        {errors.Name && errors.Name.type === "required" && (
          <div className="error">Name is required.
          </div>
        )}
         <input  className="text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

        {errors.CompanyName && errors.CompanyName.type === "required" && (
          <div className="error">Company name is required.
          </div>
        )}
         <input  className="text-input2" autoCapitalize="none" placeholder="Company Email " {...register("Email",{required:"Company email is required.",
pattern:{
  value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message:"Please enter a valid email."

}
        })}/>

        {errors.Email && (
          <ErrorMessage message={errors.Email.message}/>        )}
         <input  className="text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
         <div >
         <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />
           <div className="priv-div">
           <img src={privacy} className="privacy-image"/>
            
            {"  " }    Your privacy is important to us. 
           </div>
         <button className="contact-usButton">
         {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}

           Submit
         </button>
         <div>{result?<Result/>:null}</div>
         </div>
       </form>}
                    </div>
               </div>
           </div>
           
        </section>
        </>
        
    )
}

export default AboutUs
