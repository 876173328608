import React from "react";

//images
import backArrow from "../../../images/backarrow.svg"
import frontArrow from '../../../images/frontarrow.svg'


function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className="arrows">
      <div className="prev" onClick={prevSlide}>
        <img src={backArrow} className="prev-image" />
      </div>
      <span className="next" onClick={nextSlide}>
      <img src={frontArrow} className="next-image"/>
            </span>
    </div>
  );
}
 
export default Arrows;
