import React,{useEffect,useState} from 'react'
import  './FiuSuccess.css'
import { useLocation } from "react-router-dom";
import {BaseUrl} from '../Config/BaseUrl'

//Images
import loanAmount from '../images/loanAmount.svg'
import congratulation from '../images/congratulation.svg'
import Error from '../images/Group 2021.svg'


export default function FiuSuccess() {

    // console.log(process.env.REACT_APP_STAGE=="me","enviourment")

    const search = useLocation().search;
    
    // const id = search?new URLSearchParams(search).get('id'):"de692000-42e5-46f9-ac3d-4e677377e175"
    const id = search? new URLSearchParams(search).get('id'):"51240711-3e8f-48c9-a45c-8dc1f282f7ac&key"
    // const id = search?new URLSearchParams(search).get('id'):"69a124fd-a086-4412-8e67-811556a03c2d "

    const key = search? new URLSearchParams(search).get('key'):"qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"

    // let stageId = localStorage.getItem('fiuIdAshish')
// console.log(id,"iddd")
//     console.log(key,"keyyy")
    // console.log(id,"id is ")

    const [status,setStatus] = useState()

    let url = `https://${BaseUrl()}.ceplr.com/consents/${id}`
    let options = {method: 'GET',
            
    headers: { 
        // "authorization": ' xxxxxxxxxx' ,

      
        // "Content-Type":"application/json",
            //    "x-api-key":personalDetails.id 
            "x-api-key": key,

             
               
},

}

    useEffect(() => {
        // handleShow()
        // getFiStatus()

        getFiStatus(url, options, 5)
        
       },[],[status]);
    

    // const getFiStatus = async() =>{
    //     const requestOptions = {
    //         method: 'GET',
            
    //         headers: { 
    //             // "authorization": ' xxxxxxxxxx' ,
    
              
    //             // "Content-Type":"application/json",
    //                 //    "x-api-key":personalDetails.id 
    //                 "x-api-key": "qWB5xd3YV7ZO1S342lAg62oTir3Ke1W3hJc8bSJ8",

                     
                       
    //     },
        
                          
    //     };
    //     // console.log(requestOptions, "requestOptions")
    //     try{
    //         await fetch(`https://api-test.ceplr.com/consents/${id}`,requestOptions)
    //         .then(response => response.json())
    //         .then(res => {
                
    //             // console.log(res,"response")
    //             setStatus(res)
                 
                
                
    
                
                
    
                
    //         }
            
            
    //         )
    //     }catch(error){
    //         console.log('Error', error.response)
            
    //     }
        
            
            
            
           
    // }

    




    const getFiStatus = async (url, options = {}, retries) =>{
  await fetch(url, options)
  .then(res=>res.json())
    .then(res => {
        // console.log(res,"response")
      if (res.status == "DATA READY") {
        // console.log(res, "response ashish")
        setStatus(res)
        // return res
        
      }
      else if(res.status == "CONSENT FAILED"){
        // console.log("inside failed")
        setStatus(res)
        // return res
    }
     else if (retries > 0) {
         
        

         setTimeout(() => getFiStatus(url, options, retries - 1), 4000);

        //  console.log(res, "response 2")

         return res
             
         
         
      }
      
    //   console.log(res, "response after try")
      
      setStatus(res)

      
    //   throw new Error(res.status)
      
    }
    
    )
    .catch(error => console.error(error.message, "error message"))


    }

    // console.log(status,"status ")

  return (
    <section id ="FiuSuccess">
        {status!=null?<div className='fiuDiv'>
            <div className='greenDiv'>
                <div className='textDiv'>
                    <img src={status?status.status!="DATA READY" || status.code == "400" ?Error: congratulation:""} style={{height:"73px",width:"auto",marginBottom:"15px"}}/>
                    <div className='h1'>{status?status.status!="DATA READY" || status.code == "400" ?"Error":"Congratulations!":""}</div>
                    {/* <div className='smallText'> Your loan has been approved</div> */}
                </div>
            </div>
            <div className='restDiv'>
                <div className='detailsDiv'>
                {status?status.status!="DATA READY" || status.code == "400" ?"": <img src={loanAmount} style={{height:"73px",width:"auto",marginTop:"15px"}}/>:""}
                    
                    {status?status.status!="DATA READY" || status.code == "400" ?"":  <div className='rupeeText'>  Loan amount of ₹50,000 has been
approved</div>:""}
{status?status.status!="DATA READY" || status.code == "400" ?<div className='rupeeTextError'>Failed to fetch your bank statements, please try again.</div> :"":""}
<button className='buttonLoan'>
{status?status.status!="DATA READY" || status.code == "400" ?"Try Again":"Accept and process":""}</button>
                </div>
            </div>
        </div>:<span className="spinner-border spinner-border-sm mr-1"></span>}
        
    </section>
  )
}
