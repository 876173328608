import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

//Images
import logo from "../../images/White logo(Footer)-05 (1).png";
import { getDocumentLink } from "Config/BaseUrl";

function Footer() {
  return (
    <>
      <section id="Footer">
        <div className="container">
          {window.innerWidth > 1180 ? (
            <div className="row">
              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <img src={logo} className="footer-logo" />
                <p>
                  The Circle,4th Floor,
                  <br />
                  Huda City Centre Metro Station,Sector 29, Gurugram,Haryana
                  122002, India
                </p>
              </div>

              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>Company</h2>
                <a
                  onClick={() => {
                    window.location.href = "/aboutUs";
                  }}
                  className="list-style"
                >
                  About Us
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                  className="list-style"
                >
                  Contact Us
                </a>
              </div>
              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>Use Cases</h2>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section1";
                  }}
                  className="list-style"
                >
                  Credit & Lending
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section2";
                  }}
                  className="list-style"
                >
                  Personal Finance{" "}
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section3";
                  }}
                  className="list-style"
                >
                  Insurance{" "}
                </a>
                {/* <Link to='/'className="list-style">Resources</Link> */}
              </div>
              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>For Developers</h2>
                <a
                  target="_blank"
                  href={getDocumentLink()}
                  className="list-style"
                >
                  API Documentation
                </a>
                <a
                  target="_blank"
                  href={getDocumentLink()}
                  className="list-style"
                >
                  Postman Collection
                </a>
                <a
                  target="_blank"
                  href="http://dashboard.ceplr.com/"
                  className="list-style"
                >
                  Sandbox
                </a>
                {/* <Link to='/'className="list-style">Resources</Link> */}
              </div>
              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}
              {/* <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
       <h2>Ceplr Support </h2>
             <Link to='/' className="list-style">FAQs</Link>
             <Link to='/' className="list-style">Resources</Link>
             <Link to='/' className="list-style">Contact us</Link>
        
       </div> */}
              {/* <!--Grid column--> */}
            </div>
          ) : (
            <div className="row">
              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <img src={logo} className="footer-logo" />
                <p>
                  The Circle,3rd Floor,
                  <br />
                  Huda City Centre Metro Station,Sector 29, Gurugram,Haryana
                  122002, India
                </p>
              </div>
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>For Developers</h2>
                <a
                  target="_blank"
                  href={getDocumentLink()}
                  className="list-style"
                >
                  API Documentation
                </a>
                <a
                  target="_blank"
                  href={getDocumentLink()}
                  className="list-style"
                >
                  Postman Collection
                </a>
                <a
                  target="_blank"
                  href="http://dashboard.ceplr.com/"
                  className="list-style"
                >
                  Sandbox
                </a>
                {/* <Link to='/'className="list-style">Resources</Link> */}
              </div>

              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>Use Cases</h2>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section1";
                  }}
                  className="list-style"
                >
                  Credit & Lending
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section2";
                  }}
                  className="list-style"
                >
                  Personal Finance{" "}
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/useCase/#Section3";
                  }}
                  className="list-style"
                >
                  Insurance{" "}
                </a>
                {/* <Link to='/'className="list-style">Resources</Link> */}
              </div>
              {/* <!--Grid column--> */}
              <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
                <h2>Company</h2>
                <a
                  onClick={() => {
                    window.location.href = "/aboutUs";
                  }}
                  className="list-style"
                >
                  About Us
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                  className="list-style"
                >
                  Contact Us
                </a>
              </div>

              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}

              {/* <!--Grid column--> */}
              {/* <div className="col col-lg-3 col-md-6 mb-4 mb-md-0">
      <h2>Ceplr Support </h2>
            <Link to='/' className="list-style">FAQs</Link>
            <Link to='/' className="list-style">Resources</Link>
            <Link to='/' className="list-style">Contact us</Link>
       
      </div> */}
              {/* <!--Grid column--> */}
            </div>
          )}
        </div>
      </section>
      {/* <section id="CopyRIght">
            <img src={logo} className="Footer-Logo"/>
        </section> */}
    </>
  );
}

export default Footer;
