import React from "react";
import "./ForDevelopersCard.css";

//Images
import blueArrow from "../images/blueArrow.png";
import { getDocumentLink } from "Config/BaseUrl";

export default function ForDevelopersCard({
  src,
  title,
  description,
  buttontext,
}: any) {
  return (
    <div className="developers-CardContainer">
      {/* <div className="developers-imageContainer">
                <img src={src} className="developer-image"/>
            </div> */}

      <div className="headingText">Developers love our API</div>

      {/* <div className="forDevelopers-BigText">
{title}
            </div> */}
      <div className="forDevelopers-smallText">
        Explore the Ceplr API and use our sandbox as your virtual playground to
        get started. You'll be ready to integrate before you know it!
      </div>
      <div className="forDeveleopers-Button">
        <div className="learn-class">
          <button
            onClick={() => window.open(getDocumentLink(), "_blank")}
            className="documentation"
          >
            View documentation
            {/* <img src={blueArrow} className="blue-class"/> */}
          </button>
          <button
            onClick={() => window.open("http://dashboard.ceplr.com/", "_blank")}
            className="sandbox"
          >
            Go to the sandbox
            <img src={blueArrow} className="blue-class" />
          </button>
        </div>
      </div>
    </div>
  );
}
