import React,{useState,useEffect} from 'react'

import   './UseCases.css'
import ForDevelopersCard from './FordevelopersCard'
import {useForm} from 'react-hook-form'
import emailjs  from 'emailjs-com'
import axios from 'axios'
import { BaseUrl } from 'Config/BaseUrl'

//Images
import useCaseBg from '../images/Use cases BG 1 svg.svg'
import useCaseChart from '../images/UseCaseMain.png'
import forDeveloper1 from '../images/for developers-09.svg'
import forDeveoper2 from '../images/for developers-10.svg'
import getStartedBg from '../images/get started BG.svg'
import getStartedUg from '../images/Get Started Image-15 (1).svg'
import privacy from '../images/privacy 1.png'
import forDeveloperImage from '../images/For developers Image (1).svg'
import useCase1 from '../images/Use case 1.svg'
import useCase2 from '../images/Use case 2 .svg'
import useCase3 from '../images/Use Case 3 .svg'
import whiteArrow from '../images/whiteUseArrow.svg'
import { json } from 'stream/consumers'
import yellowEclipse from '../images/yelloEcplise.png'
import blueEclipse from '../images/blueEcplise.png'
import cyanEclipse from '../images/cyanEclipse.png'


type profile = {
  Name: string;
  Email: string;
  Mobile: number;
CompanyName:string;
Message:string
};


function UseCases() {
  const [name,setName]=useState("")
  const [email,setEmail]=useState("")
  const [companyName,setCompanyName]=useState("")
  const [mobile,setMobile]=useState("")
  const [result,showResult]=useState(false)
  const [submit,setSubmit ]  = useState(false);

 
  const ErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }
  const MobileErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }
  const Result=()=>{
    return(
      <div className="resultClass">Your message has been forwarded to our customer support team, who will contact you shortly. Thank you!</div>
    )
  }
  
const {register,handleSubmit,formState: { errors },reset}=useForm<profile>(
  {mode:"onTouched"}
)

  const onSubmit=handleSubmit(async(data:any)=>{
    setSubmit(true)
  
    console.log(data, "data")
    
    const requestOptions = {
      method: 'POST',
      
      headers: { 
          // "authorization": ' xxxxxxxxxx' ,

        
          // "Content-Type":"application/json",
                //  "x-api-key": "qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
               
                 
  },
  
       body: JSON.stringify({name:data.Name,email:data.Email,companyName:data.CompanyName,mobileNumber:data.Mobile,description:data.Message}),

       
  };
  console.log(requestOptions, "requestOptions")
  try{
      await fetch(`https://${BaseUrl()}.ceplr.com/sendMail`, requestOptions)
      .then(response => response.json())
      .then(res => {
         console.log(res,"res")
         reset()
setSubmit(false)
showResult(true)
          
      })

          // console.log('The link was clicked.');
          
      
  }catch (error) {
      console.log(error, "error");
    }

 
  // axios.post('/api/form').then((response:any)=>{
  //   if (response.data.status === 'success'){
  //       alert("Message Sent."); 
  //       // console.log(response,"response")
  //       reset()
  //   }else if(response.data.status === 'fail'){
  //       alert("Message failed to send.")
  //   }
  // })
  
  // reset()
  // }
  
  // ).catch(err=>console.log(err))
  })

  //hideResult
  // setTimeout(()=>{
  //   showResult(false)
  // },5000)
  

    return (
        <>
        <section id="UseCase">
            {/* <img src={useCaseBg} className="useCaseBgImage"/> */}
                       <div className="container">
                       <div className="row align-items center"> 
                       <div className="col col-sm-12 col-xl-6">
                       {/* <p>Use Cases</p> */}
                          
                        <img src={useCaseChart} className="chartImage"/>
                            </div>
                            <div className="col col-sm-12 col-xl-6"> 
                            <h2>Use Cases </h2>
                            <div className="useText-container">
                            
  <div className="innerUse-text">
    
    <div className="big-useText">
    <div className="round-container2">
      <img src={cyanEclipse} className="circleClass"/>
   <img src={useCase3} className="ActualIconClass"/>
    </div>
    Credit and Lending</div>
    <div className="small-UseText">
    <text > Get authentic fraud-poof bank data to empower your lending solution, while giving your customers a seamless experience.


 </text>
    </div>
    

  </div>
  <div className="innerUse-text">
    <text className="big-useText">
    <div className="round-container2">
    <img src={blueEclipse} className="circleClass"/>
    <img src={useCase2} className="ActualIconClass"/>
    </div>
    Personal Finance 
</text>
    <div className="small-UseText">
    <text >Build more powerful solutions by allowing your customers to link their bank accounts and get their account transaction data in a standard digital format.
 </text>
    </div>
    

  </div>
  <div className="innerUse-text">
    <text className="big-useText" >
    <div className="round-container2">
    <img src={yellowEclipse} className="circleClass"/>
    <img src={useCase1} className="ActualIconClass"/>
    </div>
    Insurance </text>
    <div className="small-UseText">
    <text > 
    Power your loan premium calculations with seamless access to tamper-proof income data.
      
       </text>
    </div>
    
    

  </div>
  <button className="useCase-Button" onClick={()=>{window.location.href="/useCase"}}>
  Learn more <img src={whiteArrow} className="whiteArrowImage"/>

         </button>
  {/* <div className="innerUse-text">
    <text className="big-useText">
    <div className="round-container2">
    </div>
    Insurance Provider</text>
    <div className="small-UseText">
    <text > Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum has been the industry's standard dummy

      
      
       </text>
    </div>
    
    

  </div>
  <div className="innerUse-text">
    <text className="big-useText">
    <div className="round-container2">

    </div>
    Investment advisor</text>
    <div className="small-UseText">
    <text > Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum has been the industry's standard dummy

      
      
       </text>
    </div>
    
    

  </div> */}
</div>
                            </div>
                       </div>
                          
                       </div>
        </section>
        <section id="Developers">
            <div className="container">
                          {/* <div className="headingText">Developers love <br/>our API</div> */}
               <div className="row   ">
                 <div className="col col-sm-12 col-xl-6">
               <ForDevelopersCard
            src={forDeveloper1}
            title="Developers love our API.
            "
            />
            </div>
            <div className="col col-sm-12 col-xl-6">
              <img src={forDeveloperImage} className="forDeveloperImage"/>
            </div>
               </div>
            </div>
        </section>
        <section id="GetStarted">
        {/* <img src={getStartedBg} className="getStartedBg"/> */}
           <div className="container">
           <div className="row align-items center">
                    <div className="col col-sm-12 col-xl-6">
                        {/* <p>Get Started</p> */}
                        <img src={getStartedUg} className="getstarted-Original"/>
                       
               </div>
               <div className="col col-sm-12 col-xl-6">
                  
                 {window.innerWidth>920? <form id="contact-form" className="contactWeb-formContainer" onSubmit={onSubmit}>
                       <h2>Ready to get started?</h2>
                       <p>Our experts can help you with your queries or show you a demo of our products.</p>
        
        <div className="firstRowInput">
          <div className="input1">
        <input  className="contactUs-text-input1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

{errors.Name && errors.Name.type === "required" && (
  <div className="mobileError">Name is required.</div>
)}
</div>
<div className="input2">
 <input  className="contactUs-text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

{errors.CompanyName && errors.CompanyName.type === "required" && (
  <div className="mobileError">Company name is required.</div>
)}
</div>
        </div>
        <div className="firstRowInput1">
        <div className="input1">
        <input  className="contactUs-text-input1" autoCapitalize='none' placeholder="Company Email " {...register("Email",{required:"Company email is required.",
pattern:{
 value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
 message:"Please enter a valid email."

}
       })}/>

       {errors.Email && (
         <ErrorMessage message={errors.Email.message}/>        )}
         </div>

         <div className="input2">
        <input  className="contactUs-text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
       </div>
       
        </div>

        <div className="secondRowInput">
        <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />

       
        </div>

        <div >
          <div className="priv-divContact">
          <img src={privacy} className="privacy-image"/>
           
           {"  " }    Your privacy is important to us. 
          </div>
        <button className="contactUsPage-Button">
          {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}
          Submit
        </button>
        <div>{result?<Result/>:null}</div>

        </div>
      </form>:<form id="contact-form" className="contact-formContainerMobileFirst" onSubmit={onSubmit}>
                        <h2>Ready to get started?</h2>
                        <p>Our experts can help you with your queries or show you a demo of our products.</p>
         <input  className="text-Contactinput1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

        {errors.Name && errors.Name.type === "required" && (
          <div className="error">Name is required.</div>
        )}
         <input  className="text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

        {errors.CompanyName && errors.CompanyName.type === "required" && (
          <div className="error">Company name is required.</div>
        )}
         <input  className="text-input2" autoCapitalize="none" placeholder="Company Email " {...register("Email",{required:"Company email is required",
pattern:{
  value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message:"Please enter a valid email."

}
        })}/>

        {errors.Email && (
          <ErrorMessage message={errors.Email.message}/>        )}
         <input  className="text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
         <div >
         <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />
           <div className="priv-div">
           <img src={privacy} className="privacy-image"/>
            
            {"  " }    Your privacy is important to us. 
           </div>
         <button className="contact-usButton">
         {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}

           Submit
         </button>
         <div>{result?<Result/>:null}</div>
         </div>
       </form>}
                    </div>
               </div>
           </div>
           
        </section>
        </>
    )
}

export default UseCases
