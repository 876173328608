import React,{useState} from 'react'
import Button from '@restart/ui/esm/Button'

import {useForm} from 'react-hook-form'
import emailjs  from 'emailjs-com'
import { BaseUrl } from 'Config/BaseUrl'

import  './Contact.css'

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {AddressMap} from './Maps/AddressMap'



//Images
import privacy from '../../../../images/privacy 1.png'
import blueEclipse from "../../../../images/blueEcplise.png"
import yellowEclipse from '../../../../images/yelloEcplise.png'
import phone from '../../../../images/phoneIcons.svg'
import messageIcon from '../../../../images/messageIcon.svg'
import googleMap from '../../../../images/googleMap.png'


type profile = {
    Name: string;
    Email: string;
    Mobile: number;
  CompanyName:string;
  Message:string
  };

function Contact() {

    const [result,showResult]=useState(false)
  const [submit,setSubmit ]  = useState(false);


  const Result=()=>{
    return(
      <>
      <div className="resultClass1">Your message has been forwarded to our customer support team, who will contact you shortly. Thank you!</div>
      </>
    )
  }
  const ErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"contactError":"error"}>{message}</div>
    )
   
  }
  const MobileErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"contactError":"error"}>{message}</div>
    )
   
  }
      
    const {register,handleSubmit,formState: { errors },reset}=useForm<profile>(
      {mode:"onTouched"}
    )
    
      const onSubmit=handleSubmit(async(data:any)=>{
        setSubmit(true)
        
        // console.log(data, "data")
        const requestOptions = {
          method: 'POST',
          
          headers: { 
              // "authorization": ' xxxxxxxxxx' ,
  
            
              // "Content-Type":"application/json",
                    //  "x-api-key": "qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
                   
                     
      },
      
           body: JSON.stringify({name:data.Name,email:data.Email,companyName:data.CompanyName,mobileNumber:data.Mobile,description:data.Message}),
  
           
      };
      // console.log(requestOptions, "requestOptions")
      try{
          await fetch(`https://${BaseUrl()}.ceplr.com/sendMail`, requestOptions)
          .then(response => response.json())
          .then(res => {
            //  console.log(res,"res")
             reset()
 setSubmit(false)
 showResult(true)
              
          })
  
              // console.log('The link was clicked.');
              
          
      }catch (error) {
          console.log(error, "error");
        }
      // axios.post('/api/form').then((response:any)=>{
      //   if (response.data.status === 'success'){
      //       alert("Message Sent."); 
      //       // console.log(response,"response")
      //       reset()
      //   }else if(response.data.status === 'fail'){
      //       alert("Message failed to send.")
      //   }
      // })
      
      // reset()
      // }
      
      // ).catch(err=>console.log(err))
      })
    
     //hideResult
  // setTimeout(()=>{
  //   showResult(false)
  // },5000)

    return (
        <>
            <section id="ContactMobilepage">
            </section>
            <section id="ContactUsPage1">
                <div className="container">
                <div className="row align-items center ">

                <div className="col col-sm-12 col-lg-6">
                        <div className="contact-text">
                        <div className="contact-BigText">Ready to get started? </div>
                        <div className="contact-SmallText">Our experts can help you with your queries or show you a demo of our products.
 
</div>
            {/* { <!--try now button-->} */}
                      

                        </div>
                    </div>
                    <div className="col col-sm-12 col-xl-6">
                  
                  {window.innerWidth>920?<form id="contact-form" className="contactUs-formContainer" onSubmit={onSubmit}>
                       <h2>Contact Us</h2>
                       {/* <p>Our experts can help you with your queries or show you a demo of our products.</p> */}
        
        <div className="firstRowInput">
          <div className="input1">
        <input  className="contactUs-text-input1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

{errors.Name && errors.Name.type === "required" && (
  <div className="contactError">Name is required.</div>
)}
</div>
<div className="input2">
 <input  className="contactUs-text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

{errors.CompanyName && errors.CompanyName.type === "required" && (
  <div className="contactError">Company name is required.</div>
)}
</div>
        </div>
        <div className="firstRowInput1">
        <div className="input1">
        <input  className="contactUs-text-input1" placeholder="Company Email " {...register("Email",{required:"Company email  is  required.",
pattern:{
 value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
 message:"Please enter a valid email."

}
       })}/>

       {errors.Email && (
         <ErrorMessage message={errors.Email.message}/>        )}
         </div>

         <div className="input2">
        <input  className="contactUs-text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
       </div>
       
        </div>

        <div className="secondRowInput">
        <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />

       
        </div>

        <div >
          <div className="priv-divContact">
          <img src={privacy} className="privacy-image"/>
           
           {"  " }    Your privacy is important to us. 
          </div>
        <button className="contactUsPage-Button">
          {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}
          Submit
        </button>
        <div>{result?<Result/>:null}</div>

        </div>
      </form>: <form id="contact-form" className="contact-formContainer" onSubmit={onSubmit}>
                        <h2>Ready to get started?</h2>
                        <p>Our experts can help you with your queries or show you a demo of our products.</p>
         <input  className="text-Contactinput1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

        {errors.Name && errors.Name.type === "required" && (
          <div className="error">Name is required.</div>
        )}
         <input  className="text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

        {errors.CompanyName && errors.CompanyName.type === "required" && (
          <div className="error">Company name is required.</div>
        )}
         <input  className="text-input2" autoCapitalize="none" placeholder="Company Email " {...register("Email",{required:"Comapny email is  required",
pattern:{
  value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message:"Please enter a valid email."

}
        })}/>

        {errors.Email && (
          <ErrorMessage message={errors.Email.message}/>        )}
         <input  className="text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
         <div >
         <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />
           <div className="priv-div">
           <img src={privacy} className="privacy-image"/>
            
            {"  " }    Your privacy is important to us. 
           </div>
         <button className="contact-usButton">
         {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}

           Submit
         </button>
         <div>{result?<Result/>:null}</div>
         </div>
       </form>}
                   </div>
                   </div>
                </div>
            </section>
            <section id="Map">
            <div className="container">
                <div className="row align-items center ">

                <div className="col col-sm-12 col-lg-6">
                        <div className="contact-text1">
                        <div className="Map-text">We are here for you </div>
                        
            {/* { <!--try now button-->} */}
                      

                        </div>
                        <div className="icon-Div">
                          <div className="imageContainer">
                            {/* <img src={blueEclipse} className="circleImage"/> */}
                            <img src={phone} className="phoneImage"/>
                          </div>
                          <p>+91-9632161891
</p>
                        </div>
                        <div className="icon-Div1">
                          <div className="imageContainer1">
                            {/* <img src={yellowEclipse} className="circleImage"/> */}
                            <img src={messageIcon} className="phoneImage"/>
                          </div>
                          <p>ceplr_support@arthmate.com
</p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-xl-6">
                  
                 {/* <AddressMap/> */}
                 <img src={googleMap} className="googleMap" />

                   </div>
                   </div>
                </div>
            </section>
        </>
    )
}

export default Contact
