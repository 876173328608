import React,{useState} from 'react'
import {Modal, Button} from 'react-bootstrap';
import closeIcon from '../images/closeIcon.svg'
import consentArrow1 from '../images/consentArrow1.svg'
import consentArrow2 from '../images/consentArrow2.svg'
import Hk from '../images/HK.svg'
import consentHome from '../images/consentHome.svg'
import {useParams} from "react-router-dom"
import { useLocation } from "react-router-dom";
import  './FirstScreenBlank.css';
import MobileMobile from "./MobileMobile"
import OTP from './OTP'
import SetPin from './SetPin'


//Images

import consentLogo from "../images/consentLogo.svg"


function SecondModal({history,props}) {

    
  const location = useLocation();
  
  const id = useParams()

  
  console.log(id.id,"thirdId")

    const [show, setShow] = useState(false);
  const handleClose = () =>{ setShow(false) || setOtpShow(false) || setPin(false)}
  const handleShow = () => {setShow(true) }
  
    const [otpShow, setOtpShow] = useState(false);
  // const handleOtpClose = () => setOtpShow(false);
  const handleOtpShow = () => setOtpShow(true);


    const [pin, setPin] = useState(false);
  // const handlePinClose = () => setPin(false);
  const handlePinShow = () => setPin(true);


  
  
  const handleMyShow =  () => {

    return (
        <Modal>
  <Modal.Header closeButton>
    <Modal.Title>Modal title</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Modal body text goes here.</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary">Close</Button>
    <Button variant="primary">Save changes</Button>
  </Modal.Footer>
</Modal>
    )
    // history.push(`/demo/${id.id}/third`);
    }

    

    return (
        <div className="container">
            <div className="first" >
                           
                           
                           <div className="modal-body1">
                             <div className="Name-div">
                                 <div className="purpleCirc">
                                     <img src={Hk} className="nameImage"/>
                                 </div>
                                 <div className="arrow-div">
                                     <img src={consentArrow2} className="consentArrow"/>
                                     <img src={consentArrow1} className="consentArrow"/>
                                 </div>
                                 <div className="yellowCirc">
                                 <img src={consentHome} className="nameImage1"/>
                                 </div>
                             </div>
                             <div className="modalText-container">
                                 <h2>
                                 Your data will be fetched securely from your bank with end-to-end encryption.

                                 </h2>
                                 <p>   In the next few screens you will:</p>
                             </div>
                             {/* <div className=" benefit-div">
                             <div className="benefit">Benefits</div>
                             </div> */}
                             <div className="grayDiv">
                             <ul>
                       <li className="modalList"> Connect to RBI Licensed Account Aggregator (AA) using your mobile number.
</li>
                       <li className="modalList">Create a secure AA PIN (for new users).</li>
                       <li className="modalList">Link the account from which you want to share data.</li>
                       <li className="modalList">Approve the consent request using you secure AA PIN.</li>
                     </ul>
                             </div> 
                       
                       <div className="buttonDiv">
                       <Button className="modalConnectButton1" onClick={handleShow}>Connect with mobile number </Button>
                       </div>

                       <MobileMobile show={show} hide={handleClose} otpIsShown={handleOtpShow}/>
                       <OTP OTPSHOW={otpShow} otpHide={handleClose} setPinClick={handlePinShow}/>
                       <SetPin setPinShow={pin} setPinHide={handleClose} />
                      
                       <div className="modal-footer1">
                       Powered by  
                       <img src={consentLogo} className="consentlogo"/>
                           </div>
                             
                           </div>
                     
                           </div>
        </div>
    )
}

export default SecondModal
