import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";

import "./Navbar.css";

import { Link, NavLink, Route, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";
import mfi from "../../images/Group 55.svg";

import mfi2 from "../../images/Group 56.svg";
import mfi3 from "../../images/Group 61.svg";
import mfi4 from "../../images/Group 61.png";
import mfi5 from "../../images/mfi5.png";

//Images
import logo from "../../images/ceplr_new_logo.png";
import caretUp from "../../images/caret-up.png";
import caretDown from "../../images/caret-down.png";
import navArrow from "../../images/Vector.svg";
import { getDocumentLink } from "Config/BaseUrl";

function Navbar1() {
  const [hover, setHover] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [developerHover, setDeveloperHover] = useState(false);

  const { pathname, hash } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(!isOpen);
    // can I set the styling for '.body' or 'html' here?
  }

  useEffect(() => {
    const body = document.querySelector("body");
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  const [colorChange, setColorchange] = useState(true);
  const [expanded, setExpanded] = useState(false);

  // console.log(pathname,"pathname")
  // console.log(hash,"has")

  const changeNavbarColor = () => {
    if (window.innerWidth > 1180 && window.scrollY <= 140) {
      setColorchange(true);
    } else if (window.innerWidth < 1180 && window.scrollY <= 10) {
      //  block of code to be executed if the condition1 is false and condition2 is true
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  //  window.addEventListener("noscroll",changeNavbarColor)
  window.addEventListener("scroll", changeNavbarColor);
  // window.location.reload();

  // useEffect(() => {
  //   // if not a hash link, scroll to top
  //   if (hash === '') {
  //     // window.scrollTo(0, 0);
  //   }
  //   // else scroll to id
  //   else {
  //     setTimeout(() => {
  //       const id = hash.replace('0', '0');
  //       const element = document.getElementById(id);
  //       console.log(element,"element1")
  //       if (element) {
  //         element.scrollIntoView();
  //       }
  //     }, 0);
  //   }

  // }, [pathname]); // do this on route change

  // useEffect(()=>{
  //   if(hash===hash){
  //     setTimeout(() => {
  //       const id = hash
  //       console.log(id,"iddddd")
  //       const element = document.getElementById(id);
  //       console.log(element,"element")
  //       if (element) {
  //         element.scrollIntoView();
  //       }
  //     }, 0);
  //   }

  // },[hash])

  const onMouseEnter = () => {
    if (window.innerWidth < 1120) {
      setHover(false);
    } else {
      setHover(true);
    }
  };

  const onMouseClick = () => {
    if (window.innerWidth < 1120) {
      setHover(true);
    } else {
      setHover(false);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 1120) {
      setHover(false);
    } else {
      setHover(false);
    }
  };
  const myMouse1Enter = () => {
    if (window.innerWidth < 1120) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const myMouse1Leave = () => {
    if (window.innerWidth < 1120) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const myMouse2Enter = () => {
    if (window.innerWidth < 1120) {
      setDeveloperHover(false);
    } else {
      setDeveloperHover(true);
    }
  };
  const myMouse2Leave = () => {
    if (window.innerWidth < 1120) {
      setDeveloperHover(false);
    } else {
      setDeveloperHover(false);
    }
  };
  const refreshPage = () => {};

  return (
    <div
      className={
        colorChange && window.location.pathname === "/" ? " header" : "header1"
      }
    >
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a
            className="navbar-brand"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={logo} width="120" />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={openModal}
          >
            <span> </span>
            <span> </span>
            <span> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            {window.innerWidth > 1180 ? (
              <ul className="navbar-nav ms-auto mb-2 mb-lg-2">
                <li
                  className="myNav-item "
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  //  onClick={onMouseClick}
                >
                  <a
                    onClick={() => {
                      window.innerWidth < 800
                        ? setHover(!hover)
                        : (window.location.href = "/useCase");
                    }}
                    className="myNav-links"
                  >
                    {/* <a onClick={()=>{window.location.href="/useCase"}}  className='myNav-links'  
           > */}
                    Use Cases
                    {/* <img src={caretDown} style={{marginLeft:"5px",marginTop:"3px",height:"7px",transform:"revert(-180deg)",transition:"all 0.3s ease-in-out"}}></img> */}
                    <img
                      src={caretDown}
                      className={hover ? "caret-anime1" : "caret-anime"}
                    />
                  </a>
                  {hover && (
                    <div className="one-megaMenu">
                      <div className="mySub-menuContainer">
                        <a
                          onClick={() => {
                            window.location.href = "/useCase/#Section1";
                          }}
                        >
                          <SubMenu
                            src={navArrow}
                            title="Credit & Lending"
                            description="Accounts statements and income verification 
               at your fingertips"
                            // path="/useCase/#Section1"
                          />
                        </a>

                        <a
                          onClick={() => {
                            window.location.href = "/useCase/#Section2";
                          }}
                        >
                          <SubMenu
                            src={navArrow}
                            title="Personal Finance "
                            description="Comprehensive financial data, 
               available when you need it"
                            // path="/useCase/#Section2"
                          />
                        </a>

                        <a
                          onClick={() => {
                            window.location.href = "/useCase/#Section3";
                          }}
                        >
                          <SubMenu
                            src={navArrow}
                            title="Insurance "
                            description="Seamlessly verify your customers' income"
                            // path="/useCase/#Section3"
                          />
                        </a>
                        {/* <SubMenu
               src={mfi3}
               title="Lending"
               />
               <SubMenu
               src={mfi4}
               title="Insurance Provider"
               /> */}
                      </div>
                    </div>
                  )}
                </li>
                <li
                  className="myNav-item "
                  onMouseEnter={myMouse1Enter}
                  onMouseLeave={myMouse1Leave}
                >
                  <Link to="/" className="myNav-links">
                    For Developers
                    {/* <img src={dropdown?caretUp:caretDown} style={{marginLeft:"5px",marginTop:"3px",height:"7px"}}></img> */}
                    <img
                      src={caretDown}
                      className={dropdown ? "caret-anime1" : "caret-anime"}
                    />
                  </Link>
                  {dropdown && (
                    <div className="one-megaMenu">
                      <div className="mySub-menuContainer">
                        <a target="_blank" href={getDocumentLink()}>
                          <SubMenu
                            src={navArrow}
                            title="API Documentation"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            // path='/'
                          />
                        </a>
                        <a target="_blank" href="http://dashboard.ceplr.com/">
                          <SubMenu
                            src={navArrow}
                            title="Sandbox"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            // path='/'
                          />
                        </a>
                        <a target="_blank" href={getDocumentLink()}>
                          <SubMenu
                            src={navArrow}
                            title="Postman Collection"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            // path='/'
                          />
                        </a>
                        {/* <SubMenu
               src={mfi3}
               title="Quickstart Guide"
               description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
               // path='/'
               /> */}
                      </div>
                    </div>
                  )}
                </li>
                <li
                  className="myNav-item "
                  onMouseEnter={myMouse2Enter}
                  onMouseLeave={myMouse2Leave}
                >
                  <Link to="/" className="myNav-links">
                    Company
                    {/* <img src={developerHover?caretUp:caretDown} style={{marginLeft:"5px",marginTop:"3px",height:"7px"}}></img> */}
                    <img
                      src={caretDown}
                      className={
                        developerHover ? "caret-anime1" : "caret-anime"
                      }
                    />
                  </Link>
                  {developerHover && (
                    <div className="one-megaMenu">
                      <div className="mySub-menuContainer">
                        <a
                          onClick={() => {
                            window.location.href = "/aboutUs";
                          }}
                        >
                          <SubMenu
                            src={navArrow}
                            title="About Us"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            // path='/'
                          />
                        </a>
                        <a
                          onClick={() => {
                            window.location.href = "/contact";
                          }}
                        >
                          <SubMenu
                            src={navArrow}
                            title="Contact Us"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            // path='/'
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 expanded">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Use Cases <img src={caretDown} className="caret" />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          window.location.href = "/useCase";
                        }}
                      >
                        Credit & Landing
                      </a>
                    </li>
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          window.location.href = "/useCase/#Section2";
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        Personal Finance
                      </a>
                    </li>
                    {/* <li><a className="dropdown-divider"></li> */}
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          window.location.href = "/useCase/#Section3";
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        Insurance
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    For Developers <img src={caretDown} className="caret" />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href={getDocumentLink()}
                      >
                        API Documentation
                      </a>
                    </li>
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="http://dashboard.ceplr.com/"
                      >
                        Sandbox
                      </a>
                    </li>
                    {/* <li><a className="dropdown-divider"></li> */}
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href={getDocumentLink()}
                      >
                        Postman Collection
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company <img src={caretDown} className="caret" />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          window.location.href = "/aboutUs";
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    <li className="dropdownListClass">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          window.location.href = "/contact";
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                    {/* <li><a className="dropdown-divider"></li> */}
                  </ul>
                </li>
                <li className="nav-item ">
                  <button className="signUp-Button">Sandbox</button>
                </li>
              </ul>
            )}

            {window.innerWidth > 1180 ? (
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* <button className="watchDemo-button">Watch Demo</button> */}
                <button
                  className="signUp-Button"
                  onClick={() =>
                    window.open("http://dashboard.ceplr.com/", "_blank")
                  }
                >
                  Sandbox
                </button>
              </ul>
            ) : null}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar1;
