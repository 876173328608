import React from 'react'

import  './Cards.css'


//Images

import cyan from '../../../images/cyan.png'
import yellow from '../../../images/yellow.png'
import pink from '../../../images/pink.png'
import cardVector from '../../../images/card-vector.png'
import rectangle1 from '../../../images/Rectangle 26.png'
import rectangle2 from '../../../images/Rectangle 50.png'
import rectangle3 from '../../../images/Rectangle 51.png'
import rectangle4 from '../../../images/Rectangle 52.png'
import otherValueCircle from '../../../images/othevaluecircle.png'
import card1 from '../../../images/card1.svg'
import card2 from '../../../images/card2.svg'
import api from '../../../images/api 1.svg'
import card3 from '../../../images/card3.svg'
import card4 from '../../../images/card4.svg'
import understanding from '../../../images/understanding (1) 1.svg'

function Cards() {
    return (
        <>
      
        <section id="Cards">
            <div className="container">

               <div className="text-center">
               <h2>The easiest way to collect data</h2>
                   
                   
               </div>
               <div className="row  align-items center justify-content center ">
                   <div className="col col-lg-6 col-md-12 mb-12 mb-md-12 ">
                       <div className="card">
                           <div className="card-body ">
                           <div className="cardbig-useText">
    <div className="cardround-container2">
      <img src={otherValueCircle} className="cardcircleClass"/>
   <img src={card1} className="cardActualIconClass"/>
    </div>
    Tamper-proof data through the 
magic of AAs</div>
                              <div className="text"> Ceplr works in partnership with an RBI-licensed Account Aggregator, ensuring the data is fetched directly from the source.

</div>
<div className="talk">
<a  className="talkUs" onClick={()=>{window.location.href="/contact"}}>
Know more
<img src={cardVector} className="cardVectorClass"/>
</a>
</div>
                           </div>
                       </div>
                      
                
                   </div>
                   <div className="col col-lg-6 col-md-12 mb-12 mb-md-12 ">
                       <div className="card">
                           <div className="card-body ">
                           <div className="cardbig-useText">
    <div className="cardround-container2">
      <img src={otherValueCircle} className="cardcircleClass"/>
   <img src={card2} className="cardActualIconClass"/>
    </div>
    End to end integration in less 
than two weeks</div>
                              <div className="text"> integrating our simplified APIs is a breeze and in our experience companies are able to integrate within 14 days.
</div><div className="talk">
<a  className="talkUs" onClick={()=>{window.location.href="/contact"}}>
Explore sandbox<img src={cardVector} className="cardVectorClass"/>
</a>
</div>

                           </div>
                       </div>
                      
                
                   </div>
                   <div className="col col-lg-6 col-md-12 mb-12 mb-md-12">
                       <div className="card">
                           <div className="card-body ">
                           <div className="cardbig-useText">
    <div className="cardround-container2">
      <img src={otherValueCircle} className="cardcircleClass"/>
   <img src={card3} className="cardActualIconClass"/>
    </div>
    Bank grade security</div>                             <div className="text"> Our APIs are compliant with DEPA and ReBiT specifications, ensuring secure access to financial information.

</div> 
<div className="talk">
<a  className="talkUs" onClick={()=>{window.location.href="/contact"}}>
Know more
<img src={cardVector} className="cardVectorClass"/>
</a>
</div>

                           </div>
                       </div>
                      
                
                   </div>
                   <div className="col  col-lg-6 col-md-12 mb-12 mb-md-12">
                       <div className="card">
                           <div className="card-body ">
                           <div className="cardbig-useText">
    <div className="cardround-container2">
      <img src={otherValueCircle} className="cardcircleClass"/>
   <img src={card4} className="cardActualIconClass"/>
    </div>
    Financial Insights </div>
                            
                              <div className="text"> Our analytics layer built over the raw data gives you deeper insights into your customer's finances.

</div>
<div className="talk">
<a  className="talkUs" onClick={()=>{window.location.href="/contact"}}>
Talk to us
<img src={cardVector} className="cardVectorClass"/>
</a>
</div>

                           </div>
                       </div>
                      
                
                   </div>
               </div>
                
            </div>
        </section>
        </>
        
    )
}

export default Cards
