import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import "./ConsentSuccess.css";


export default function ConsentSuccess() {
  const search = useLocation().search;
  let linkToCopy = search
    ? new URLSearchParams(search).get("url")
    : "Send consent request to get the url";
  let id = search ? new URLSearchParams(search).get('id') : "s3Z1G1xqp82y5TbjX7J093ncn1pozl0Q2zHL44YU"
  let tempId = search ? new URLSearchParams(search).get('tempId') : "Configuration-1106-8552"
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    const textArea = document.createElement("textarea");
    textArea.value = linkToCopy;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setIsCopied(true);

    // Reset the "Copied" state after a delay (e.g., 30 seconds)
    setTimeout(() => {
      setIsCopied(false);
    }, 30000);
  };
  const handleRegenerateClick = () => {
    setIsCopied(false);
    window.location.href = `/demo?id=${id}&tempId=${tempId}`;
  };

  return (
    <div className="consent_success_screen">
      <div className="box-container">
        {/* Display the link inside the box */}
        <div className="link-text">
          <strong>Link:</strong> {linkToCopy}
        </div>

        {/* Copy button at the top-right corner */}
        <a className="copy-button" onClick={handleCopyClick}>
          {isCopied ? "Copied!" : "Copy"}
        </a>
      </div>
        {/* Regenerate button */}
        <button className="regenerate-button" onClick={handleRegenerateClick}>
          Regenerate Link
        </button>
    </div>
  );
}
