import React,{useState} from 'react'
import {Modal, Button} from 'react-bootstrap';

function SixModal({history}:any) {
    const [show, setShow] = useState(false);
  const handleClose = () => (setShow(false));
  const handleShow = () => setShow(true);

    return (
        <section id="FirstScreenBlank1">
            <div className="container">
                <div className="row align-items center justify-content center">

<Button   className="tryNow-btn" onClick={handleShow}>
                            
                            Six
                           
                        </Button>
                        </div>
            </div>
        </section>


    )
}

export default SixModal
