import React from "react";
import sliderImage from "./sliderImage";
// import sliderImages from "./sliderImages";


console.log(sliderImage.map(item=>item.title))

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((item, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image" src={item.slidePicture} alt="" />
          {/* <h2 className="slide-title">{item.title}</h2>
          <h3 className="slide-text">{item.description}</h3> */}
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
