import Button from '@restart/ui/esm/Button'
import React,{useState,useEffect} from 'react'
import  './Hero.css'
import SliderContent from "./Pages/json/SliderContent"
import Dots from "./Pages/json/Dots";
import Arrows from "./Pages/json/Arrows";
import sliderImage from "./Pages/json/sliderImage";
import  './Pages/json/slider.css'


//Images
import heroImage from "../images/Hero-OgImage.svg"
import whyCeplr from "../images/about us.svg"
import Mobile from "../images/how it works image.svg"
import howDoesItwork1 from"../images/how does it work-05.svg"
import howDoesItwork2 from "../images/how does it work-06.svg"
import howDoesItwork3 from "../images/how does it work-07.svg"
import howDoesBg from '../images/Use cases BG 1 svg.svg'
import mobileHero from '../images/Hero-MobileImage.png'

import whyCeplrBg from '../images/why ceplr BG (2).svg'
import whitearrow from '../images/whiteUseArrow.svg'
import thumbDown from '../images/thumb_down.svg'
import thumbUp from '../images/thumb_up.svg'

import { dashboardUrl } from 'Config/BaseUrl';



const len = sliderImage.length - 1;


function Hero() {
    const [activeIndex, setActiveIndex] = useState(0);
  console.log(activeIndex,"activeIndex")

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  

    return (<>
        <section id="Hero">
            
            <div className=" container ">
                <div className="row align-items center">
                    <div className="col col-sm-12 col-lg-6">
                        <div className="hero-text">
                        <div className="weMake-Big">Get seamless access to financial data </div>
                        <div className="weMake-small">Ceplr integrates with your application to facilitate real-time access to your customers' financial data directly from banks, enabling powerful solutions with user experiences your customers will love. 
</div>
            {/* { <!--try now button-->} */}
            <Button onClick={()=> window.open(dashboardUrl(), "_blank")}  className="tryNow-btn">
                            
                            Try it now
                            <img src={whitearrow} style={{height:"11px",marginLeft:"10px",marginTop:"0px"}}/>
                        </Button>
                      

                        </div>
                    </div>
                    <div className="col col-sm-12 col-xl-6 ">
                    
                        <img src={heroImage} className="hero-Image"/>
                        <img src={mobileHero} className="hero-MobileImage"/>
                   
                </div>
                </div>
            </div>
        </section>
        <section id="whyCeplr">
        {/* <img src={whyCeplrBg} className="whyCeplrBG"/> */}
        <div className="container">
        <div className="row align-items center ">
            <div className="col col-sm-12 col-xl-6">
            <img src={whyCeplr} className="whyCeplr-Image"/>
                  
            </div>
            <div className="col col-sm-12 col-xl-6 ">
            <div className="whyCeplrText-Container">

{/* <div className="headingWhy-Ceplr">Why Ceplr?</div> */}
<div className="whyCeplrBigText">
A next-gen onboarding <br/> experience 
</div>
<div className="whyCeplr-smallText1">
Whether you need bank statements of the last six months or weekly access to transaction data, Ceplr enables your customers to instantly share their financial data in a standard digital format.

</div>
{/* <div className="whyCeplr-smallText2">
We provide comprehensive documentation, developer guides, sample code, sample data, and everything you need to get up and running as fast as possible.
</div> */}

</div>
<div className="whyCeplr-linksDiv">
  <div className="whyLink1">
    <img src={thumbDown} className="thumbs"/>
    Manual doc uploads
  </div>  
  <div className="whyLink2">
  <img src={thumbDown} className="thumbs"/>
  Fraudulent data
    
  </div>
</div>
<div className="whyCeplr-linksDiv1">
  <div className="whyLink1">
    <img src={thumbDown} className="thumbs"/>
    Lengthy onboarding
  </div>  
  <div className="whyLink2">
  <img src={thumbUp} className="thumbs"/>
  Boosted conversions
    
  </div>
</div>
                </div>
           
        </div>
        
        </div>
        
        </section>

        <section id="Mobile">
            {/* <img src={howDoesBg} className="howDoesBg"/> */}
                    <div className="container">
                <div className="row align-items center">
                    <div className="col col-sm-12 col-xl-6">
                    <div className="howDoesitContainer">
                            {/* <div className="howDoes-Heading">
                                How It Works?
                            </div> */}
                        <div className="howDoes-BigText">
                        How does it work? <br/> It couldn't be more simple.
                        </div>
                        <div className="text-container">
                            {sliderImage.map((item,index)=><div className="inner-text">
                            {/* <img src={item.picture} className="how-doesImage"/> */}
                              <div className="big-text">
                             <div className="round-container">

                             <img src={item.image} className="howDoesCircleClass"/>
                             <img src={item.picture} className="howDoesActualIconClass"/>
                            
                                  </div>
                                     {item.title}</div>
                                            <div className="small-text">
                          <text > {item.description} </text>
                                </div>
  
                            </div>)}
                            
                     

                        </div>
                        </div>
                        {/* <img src={Mobile} className="Mobile-image"/> */}
        
                    </div>
                    <div className="col col-sm-12 col-xl-6">
                    <div className="slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <Dots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex:any) => setActiveIndex(activeIndex)}
      />
    </div>
                       
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Hero
