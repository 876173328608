import React,{useEffect,useState} from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import Home from "./Components/Pages/Home";
import UseCasesLinks from "./Components/Pages/UseCasesLinks";
import AboutUs from "./Components/Pages/json/About/AboutUs";
import Contact from "./Components/Pages/json/About/Contact";
import FirstScreenBlank from "./Consent Flow/FirstScreenBlank";
import WelcomeScreen from "./Consent Flow/WelcomeScreen"
import DemoScreen1 from "./Consent Flow/DemoScreen1";
import FiuSuccess from "./Consent Flow/FiuSuccess";
import PdfSuccess from "./Consent Flow/PdfSuccess";
import ConsentSuccess from "./Consent Flow/ConsentSuccess";


import { Switch,Route,BrowserRouter as Router,useLocation } from "react-router-dom";

import ConsentFooter from "./Components/Footer/ConsentFooter"


import Navbar1 from './Components/Navbar1/Navbar1'
import MobileNavbar from "./Mobile navbar/MobileNavbar";
import Navbar from './Components/Navbar/Navbar'
import SecondModal from "./Consent Flow/SecondModal";

// import  './App.css'
import Footer from "./Components/Footer/Footer";

import  './index.css'
import Slider from "./Components/Pages/json/Slider";
import ConsentRoutes from "./ConsentRoutes/ConsentRoutes";
// import ContactForm from './Components/ContactForm'

import Demo from './ConsentRoutes/Demo'



const App=()=>{

    
    const [personalDetails,setPersonalDetails] = useState({name:"",mobile:"",email:"",id:"",tempId:""})
    const location = useLocation();

    const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, [])

    return (
        <Router>
        {/* <Navbar/> */}
       {/* {window.innerWidth<1180?<MobileNavbar/>: <Navbar1/>} */}
      {(location.pathname !== '/demo' && location.pathname !== '/demo/consentSuccess'&&  location.pathname !== '/demo/demo1' && location.pathname !== '/demo/:id' && location.pathname !== '/approved'  && location.pathname !== '/uploaded') ? <Navbar1 />:
      null} 
       {/* <MobileNavbar/> */}
        <Switch>
            {/* <Route exact path="/" component={Home}/> */}
            {/* <Route exact path="/" component={Home}/> */}
            <Route  exact path="/" >
                <Home/>
                </Route>
            <Route  path='/useCase' component={UseCasesLinks}/>
            <Route path='/aboutUs' component={AboutUs} />
            <Route path='/contact' component={Contact}/>
             
             <Route exact path = "/demo"><DemoScreen1 setPersonalDetails={setPersonalDetails}/>
            
             </Route>
             <Route exact path = "/demo/consentSuccess"><ConsentSuccess/>
             </Route>
            <Route exact path = '/demo/demo1' >
                <WelcomeScreen personalDetails={personalDetails}/>
                
                </Route>
                <Route path='/approved/' component={FiuSuccess}/>
                <Route path='/uploaded/' component={PdfSuccess}/>

           <ConsentRoutes/>
           
        </Switch>
        {/* <Slider/>
        <Services/>
        <Summary/> */}

{location.pathname !== '/demo' && location.pathname !== '/demo/consentSuccess' && location.pathname !== '/demo/demo1' && location.pathname !== '/approved' && location.pathname !== '/uploaded' ?<Footer/>:

<ConsentFooter/>} 
 
        
        </Router>
       
       
    )
}


export default App