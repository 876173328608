import React,{useEffect,useState} from 'react'
import   "./SubMenu.css"
import { Link,useLocation,Router} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import { Hash } from 'crypto';


function SubMenu({src,title,description,path,router}:any) {

//     function useForceUpdate() {
//         let [value, setState] = useState(true);
//         return () => setState(!value);
//       }
// let forceUpdate=useForceUpdate()
    
    return (
    //    <a  className="hash-class"  onClick={()=>{window.location.href=path}} >
        <div className="sub-menucontainer">
            
           
           
            <div className="title-text">
                {title}
            </div>
            {/* <img src={src} className="sub-image-container" /> */}
            {/* <div className="small-subTextContainer">
            <text>
            {description}
            </text>
                
            </div> */}
            {/* <div className=""></div> */}
            </div>
           
            // </a>
       
    )
}

export default SubMenu
