import React,{useState} from 'react'
import {Modal, Button} from 'react-bootstrap';
import {useParams} from "react-router-dom"


function ThirdModal({history}:any) {

    const [show, setShow] = useState(false);
  const handleClose = () => (setShow(false));
  const handleShow = () => {
    history.push(`/demo/${id}/third/fourth`);

    }

    const { id } = useParams<{ id:any }>();

    console.log(id,"fourth id")

    return (
        <section id="FirstScreenBlank1">
            <div className="container">
                <div className="row align-items center justify-content center">

<Button   className="tryNow-btn" onClick={handleShow}>
                            
                            Third
                           
                        </Button>
                        </div>
            </div>
        </section>


    )
}

export default ThirdModal
