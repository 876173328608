import React from 'react'
import { Switch,Route,BrowserRouter as Router,useLocation, Redirect } from "react-router-dom";


//Screens
import SecondModal from '../Consent Flow/SecondModal';
import ThirdModal from "../Consent Flow/ThirdModal";
import FourthModal from "../Consent Flow/FourthModal";
import Fifthmodal from "../Consent Flow/Fifthmodal";
import SixModal from "../Consent Flow/SixModal";
import WelcomeScreen from "../Consent Flow/WelcomeScreen"




function ConsentRoutes() {
    return (
        <div>
            <Router>
                <Switch>
            {/* <Route   exact path="/demo/:id" component={WelcomeScreen}  /> */}
            <Route   exact path="/demo/demo1/:id" component={SecondModal}  />
            <Route exact path="/demo/:id/third" component={ThirdModal}/>
            <Route exact path="/demo/:id/third/fourth" component={FourthModal}/>
            <Route exact path="/demo/:id/third/fourth/fifth" component={Fifthmodal}/>
            <Route exact path="/demo/:id/third/fourth/fifth/six" component={SixModal}/>
           
            </Switch>
             

            </Router>
           
        </div>
    )
}

export default ConsentRoutes
