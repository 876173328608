import React,{useState} from 'react'
// import {Modal, Button} from 'react-bootstrap';

function FourthModal({history}:any) {

    const [show, setShow] = useState(false);
  const handleClose = () => (setShow(false));
  const handleShow = () => {
    history.push('/links/uuid/third/fourth/fifth');
    }

    return (
        <section id="FirstScreenBlank1">
        <div className="container">
            <div className="row align-items center justify-content center">

{/* <Button   className="tryNow-btn" onClick={handleShow}>
                        
                    Fourth
                       
                    </Button> */}
                    </div>
            </div>
        </section>


    )
}

export default FourthModal
