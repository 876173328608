import React,{useState} from 'react'

import {Modal, Button} from 'react-bootstrap';

//images
import consentLogo from "../images/consentLogo.svg"

function MobileMobile(props) {

    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (props.show) ?(
        <div>
             <Modal show={props.show} onHide={props.hide}  backdrop="static"
                       size="lg"
      aria-labelledby="contained-modal-title-center"
      end
                       >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          {/* <Button className="modalConnectButton1" onClick={props.otpIsShown}>Connect with otp </Button> */}
        </Modal.Header>
        <Modal.Body>
        <h2>Enter mobile number</h2> 
        <input className="mobileInputModal"></input>
         <Button className="modalConnectButtonmobileModal" onClick={props.otpIsShown}>Connect with otp </Button>
         </Modal.Body>

  <Modal.Footer>
  
                       Powered by  
                       <img src={consentLogo} className="consentlogo"/>
                           
         
  </Modal.Footer>

     
      </Modal>

        </div>
    ) : ""
}

export default MobileMobile
