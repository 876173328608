import React, { useState, useEffect } from 'react'

import './DemoScreen1.css'

import { useForm } from 'react-hook-form'
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'



//Images
import checkMark from "../images/checkMark.svg"
import backArrow from '../images/backArrowSmall.svg'
import personalDetails from '../images/Personal Details image.svg'
import { BaseUrl, RedirectUrl } from 'Config/BaseUrl';

function DemoScreen1({ setPersonalDetails }: any) {

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [bankList, setBankList] = useState<any[]>([])
  const [isActive, setIsActive] = useState(false)
  const [selectedName, setSelectedName] = useState("---Select Your Bank---")
  const [searched, setSearched] = useState("")

  // const [personalDetails,setPersonalDetails] = useState({})

  const { push } = useHistory()

  const search = useLocation().search


  //  console.log(selectedName, "name selected")




    let id = search ? new URLSearchParams(search).get('id') : "s3Z1G1xqp82y5TbjX7J093ncn1pozl0Q2zHL44YU"

    let tempId = search ? new URLSearchParams(search).get('tempId') : "Configuration-1106-8552"


  const [postId, setPostId] = useState({
    code: "",
    data: { url: "" }
  })
  //  console.log(id,"id")
  //  console.log("temp id", tempId)

  const { register, handleSubmit, formState: { errors }, reset } = useForm(
    { mode: "onTouched" }
  )

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  //   const handleCLick = () =>{
  // setIsActive(!isActive)
  //   }
  useEffect(() => {
    fetchBankList(id)
  }, []);

  const fetchBankList = async (id: any) => {
    console.log(personalDetails, "personalDetails")
    const requestOptions = {
      method: 'GET',
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        "x-api-key": id
      },


    };
    // console.log(requestOptions, "requestOptions")
    try {
      await fetch(`https://${BaseUrl()}.ceplr.com/fip`, requestOptions)
        .then(response => response.json())
        .then(res => {
          //  console.log(res, "response bank")
          setBankList(res.data)

        })
      // console.log('The link was clicked.');
    } catch (error) {
      console.log(error, "error");
    }

  }
  let bankObject: any = {};

  const handleShow = async (personalDetails: any) => {

    // console.log(personalDetails.bank==undefined, "data")

    if (selectedName != undefined || selectedName != "---Select Your Bank---") {
      if (
        bankList.map(item => item.fip_name).includes(selectedName)) {
        bankObject = bankList.find(element => element.fip_name == selectedName)
      }
    }

    // console.log(bankObject, "bank object")
    localStorage.setItem('fiuId', personalDetails.id)
    const requestOptions = {
      method: 'POST',
      headers: {
        // "authorization": ' xxxxxxxxxx' ,
        // "Content-Type":"application/json",
        "x-api-key": personalDetails.id
      },

      body: JSON.stringify({
        customer_name: personalDetails.Name, customer_contact: personalDetails.Mobile, customer_email: personalDetails.Email,
        // fiu_uuid:"ap-south-1:9a4f16b5-e2dc-4557-823b-2d02cdce1465", 
        configuration_uuid: personalDetails.tempId,
        loan_application_uuid: generateUUID(),
        redirect_url: RedirectUrl(),
        fip_id: bankObject.fip_id,

      })
    }
    // console.log(requestOptions, "requestOptions")
    try {
      await fetch(`https://${BaseUrl()}.ceplr.com/fiu/consents`, requestOptions)
        .then(response => response.json())
        .then(res => {
          setPostId(res)
          // setLoading(true)
          if(process.env.REACT_APP_STAGE==="prod"){
            window.location.href = `/demo/consentSuccess?id=${id}&tempId=${tempId}&url=${res.data.url}`;
          }else{
            window.location.replace(`${res.data.url}&key=${personalDetails.id}`);
          }
          // setLoading(false)

        })
      // console.log('The link was clicked.');
    } catch (error) {
      console.log(error, "error");
    }
  }

  const onSubmit = ((data: any) => {
    // console.log(data,id, "details")
    setSubmit(true)
    handleShow(data)
  })

  const onChange = (item: any) => {
    // console.log(item.fipName)
    setSelectedName(item.fip_name)
    // setSearched(item.name)
  }
  let listOfBanks: any = []
  const searchingBank = (bankList: any) => {
    return bankList.filter((item: any) =>
      // console.log(item)})
      item.fip_name.toLowerCase().indexOf(searched.toLowerCase()) > -1)
  }


  const ErrorMessage = ({ message }: any) => {
    return (
      <div className="personalError">{message}</div>
    )

  }
  const MobileErrorMessage = (message: any) => {
    return (
      <div className="personalError">{message.message}</div>
    )

  }



  return (
    <section id="DemoScreen1">
      <div className="container-fluid">
        <div className="row align-items center justify-content center">
          <div className="ConnectHeader">
            <img src={backArrow} className="arrowHeader" />
            <div className="incomeHeader"> Personal Details</div>
          </div>

          <div className="stepDiv">
            <div className="step-wrapper">
              <div className="stepNumber stepNumber-active "> <img src={checkMark} className="checkMarkClass" /> </div>
              <div className="dividerLine dividerLine-active"></div>
            </div>
            <div className="step-wrapper">
              <div className="stepNumber stepNumber-active "> <img src={checkMark} className="checkMarkClass" /> </div>
              <div className="dividerLine dividerLine-active"></div>
            </div>
            <div className="step-wrapper">
              <div className={submit ? "stepNumber stepNumber-active " : "stepNumber stepNumber-disabled "}>{submit ? <img src={checkMark} className="checkMarkClass" /> : ""}</div>

              <div className="dividerLine dividerLine-active"></div>
            </div>
            <div className="step-wrapper">
              <div className="stepNumber stepNumber-disabled "></div>
              {/* <div className="dividerLine"></div> */}
            </div>


          </div>

          <div className="imageDivDemo">
            <img src={personalDetails} className="perosnalImage" />
          </div>
          <div className='FormDemoContainer'>
            <form id="contact-form" className={isActive ? "contact-formDemo-active" : "contact-formDemo"} onSubmit={handleSubmit(onSubmit)}>
              <label className='inputNameLabel'>
                Name
                <input className={errors.Name ? "errorInputClassDemo" : 'inputClassDemo'} {...register("Name", { required: false })} />
                {/* {errors.Name && errors.Name.type === "required" && (
                  <div className="personalError">Name is required.</div>
                )} */}
              </label>
              <label className='inputNameLabel'>
                Mobile Number
                <input className={errors.Mobile ? "errorInputClassDemo" : 'inputClassDemo'} type="tel" {...register("Mobile", {
                  required: "Mobile number is required.",
                  pattern: {
                    value: /^[0]?[6789]\d{9}$/,
                    message: "Please enter a valid mobile number."

                  }
                })} />
                {errors.Mobile && (
                  <MobileErrorMessage message={errors.Mobile.message} />)}


              </label>
              <label className='inputNameLabel'>
                Email
                <input className={errors.Email ? "errorInputClassDemo" : 'inputClassDemo'} {...register("Email", {
                  required: false,
                  // pattern: {
                  //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  //   message: "Please enter a valid email."

                  // }
                })} />
                {/* {errors.Email && (
                  <ErrorMessage message={errors.Email.message} />)} */}

              </label>

              {/* <label className='inputNameLabel'>
                Select Bank */}
              <div className={isActive ? 'dropDownWrapperActive' : 'dropDownWrapper'}>
                <div className={isActive ? "select-option_top" : 'select-option'} onClick={() => setIsActive((prev) => !prev)}>
                  <div className={isActive ? 'bankText' : 'bankText'}>
                    <input
                      placeholder={selectedName ? selectedName : searched}
                      value={selectedName || searched}
                      className="searchingInput"
                      onChange={(e) => {

                        setSearched(e.target.value)
                        onChange(e.target)
                      }}
                      onClick={() => {
                        setSearched("")
                        setSelectedName('')
                        setIsActive((prev) => prev)
                      }}
                    />
                  </div>
                  <div className={isActive ? 'arrow up' : 'arrow down'} />

                </div>
                {isActive ?
                  <div className='dropDownOptions'>
                    {searchingBank(bankList).map((item: any, index: any) =>
                    (<div key={index} className='options'
                      onClick={() => {
                        setSearched("")
                        onChange(item)
                        setIsActive(false)
                      }}
                    ><img src={item.fip_logo_uri} style={{height:"15px", marginRight:"5px"}}></img> {item.fip_name} </div>))}
                  </div>
                  : ""}
              </div>
              {/* <select {...register("Bank")} className="select-option">
              <option hidden>Select a gender</option>
        
        {bankList.map((item,index)=>(<option value={item.fipName} key={index} className="dropDown-content">{item.fipName}</option>))}
        
      </select> */}
              {/* </label> */}

              <input  {...register("id")} className="hiddenInput" value={id ? id : ""} />
              <input  {...register("tempId")} className="hiddenInput" value={tempId ? tempId : ""} />

              {isActive ? "" : <button className="proceedButtonDemo">
                {submit ? <span className="spinner-border spinner-border-sm mr-1"></span> : "Proceed"}


              </button>}




              {/* <Button className="proceedButtonDemo" >
                       {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:"Submit"}
                        </Button>
                      */}
              {/* <input type="submit"/> */}
            </form>

          </div>
        </div>
      </div>
    </section>
  )
}

export default DemoScreen1
