import React from 'react'

import {Modal, Button} from 'react-bootstrap';

//Images
import consentLogo from "../images/consentLogo.svg"

function SetPin(props) {
    return (props.setPinShow) ? (
        <div>
            <Modal show={props.setPinShow} onHide={props.setPinHide}  backdrop="static"
                       size="lg"
      aria-labelledby="contained-modal-title-vEnd"
      end
                       >
         <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          {/* <Button className="modalConnectButton1" onClick={props.otpIsShown}>Connect with otp </Button> */}
        </Modal.Header>
        <Modal.Body>
        <h2>Set Pin</h2> 
        <input className="mobileInputModal" keyboardType = 'numeric'></input>
         <Button className="modalConnectButtonmobileModal" onClick={props.setPinClick}>Go to next  </Button>
         </Modal.Body>

  <Modal.Footer>
  
                       Powered by  
                       <img src={consentLogo} className="consentlogo"/>
                           
         
  </Modal.Footer>

   
      </Modal>

        </div>
    ): ""
}

export default SetPin
