import React, { useState, useMemo, useEffect } from 'react'

import './WelcomeScreen.css'
import './FirstScreenBlank.css';

import { useParams } from "react-router-dom"
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'

import {BaseUrl,RedirectUrl} from '../Config/BaseUrl'
// import redirectUrl from '../Config/BaseUrl'

//Images
import backArrow from '../images/backArrowSmall.svg'
import checkMark from "../images/checkMark.svg"
import pinkImage from "../images/pinkImage.svg"
import greenCheck from '../images/greenCheckmark.svg'


function WelcomeScreen({ personalDetails }) {

    console.log(personalDetails, "personal details ")

    // console.log(personalDetails.Name, "it is what it is name ")




    const [postId, setPostId] = useState({
        code: "",
        data: { url: "" }
    })
    const [loading,setLoading] = useState(false)
//   const handleClose = () => (setShow(false));

  const history = useHistory()


  let id = useParams()
  id=postId.data.url.substring(postId.data.url.lastIndexOf('/') + 1)


//   console.log(id,"myId")
// //   console.log(postId,"postIdurl")

//   console.log(id,"iddddddddddddddd")

//   useEffect(() => {
//     // You need to restrict it at some point
//     // This is just dummy code and should be replaced by actual
//     if (!postId) {
//         handleShow();
//     }
//   }, []);


//
const  generateUUID =() => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
  }

  console.log(generateUUID(),"UUIDDD")


  //redircect route
  const routeChange=()=> {
    let path = postId.data.url;
    window.location.replace(path);
  }

  const  handleShow = async () => {

   

    setLoading(true)
    // event.preventDefault();
    // setActiveStep((prevActiveStep) => prevActiveStep + 4);

   


    const requestOptions = {
        method: 'POST',
        
        headers: { 
            // "authorization": ' xxxxxxxxxx' ,

          
            // "Content-Type":"application/json",
                   "x-api-key":personalDetails.id 
                 
                   
    },
    
         body: JSON.stringify({customer_name:personalDetails.Name,customer_contact:personalDetails.Mobile,customer_email:personalDetails.Email,
            // fiu_uuid:"ap-south-1:9a4f16b5-e2dc-4557-823b-2d02cdce1465", 
            consent_template_uuid: personalDetails.tempId,
         
         redirect_url:RedirectUrl()})

         
    };
    console.log(requestOptions, "requestOptions")
    try{
        await fetch(`https://${BaseUrl()}.ceplr.com/fiu/consents`, requestOptions)
        .then(response => response.json())
        .then(res => {
            setPostId(res)
            // console.log(res.data,"response")
            // console.log(postId.data,"postId")
             
            id=res.data.url.substring(res.data.url.lastIndexOf('/') + 2)
            console.log(id,"the id that I am getting")
            // // 
            // setLoading(true)
            
              
                window.location.replace(`${res.data.url}&key=${personalDetails.id}`);
                // setLoading(false)

            
        })

            // console.log('The link was clicked.');
            
        
    }catch (error) {
        console.log(error, "error");
      }
  }


useEffect(() => {
    // handleShow()
    // console.log("I am here ",postId.data)
    localStorage.setItem('fiuIdAshish',personalDetails.id)
    
   }, [postId]);


    return (
        <section id="WelcomeScreen">
            <div className="container-fluid">
                <div className="row align-items center justify-content center">
                    <div className="ConnectHeader">
                        {/* <img src={backArrow} className="arrowHeader" /> */}
                        <div className="incomeHeader"> Account Verification</div>
                    </div>

                    <div className="stepDiv">
                        <div className="step-wrapper">
                            <div className="stepNumber stepNumber-active "> <img src={checkMark} className="checkMarkClass" /> </div>
                            <div className="dividerLine dividerLine-active"></div>
                        </div>
                        <div className="step-wrapper">
                            <div className="stepNumber stepNumber-active "> <img src={checkMark} className="checkMarkClass" /> </div>
                            <div className="dividerLine dividerLine-active"></div>
                        </div>
                        <div className="step-wrapper">
                            <div className="stepNumber stepNumber-active "> <img src={checkMark} className="checkMarkClass" /> </div>
                            <div className="dividerLine dividerLine-active"></div>
                        </div>
                        <div className="step-wrapper">
                            <div className={loading ? "stepNumber stepNumber-active " : "stepNumber stepNumber-disabled "}>{loading ? <img src={checkMark} className="checkMarkClass" /> : ""}</div>
                            {/* <div className="dividerLine"></div> */}
                        </div>


                    </div>

                    <div className="PinkDiv">
                        <div className="pinkImageDiv">
                            <img className="pinkImage" src={pinkImage} />
                        </div>
                        <div className="pinkTextDiv">
                            <h2>
                                Please share your bank statement
                            </h2>
                            <p> This information will use to acssess your
                                loan eligibility.</p>
                        </div>
                    </div>

                    <div className="grayDivWelcome">
                        <h4>Choose a way to share the details:</h4>

                        <div className="cardDiv">
                            <div className="listStyleCardDiv">
                                <div className="circle">
                                    <div className="point"></div>
                                </div>
                                <ul className="welcomeUnorderList">
                                    Share via Account Aggregator

                                </ul>
                            </div>

                            <li className="WelcomeList"> <img src={greenCheck} className="greenCheck" /><div className="listText">Information shared digitally in a just few clicks.</div>
                            </li>
                            <li className="WelcomeList"><img src={greenCheck} className="greenCheck" /><div className="listText">Highly secure with end-to-end encryption.</div></li>
                            <li className="WelcomeList"><img src={greenCheck} className="greenCheck" /><div className="listText">Licensed and regulated by the Reserve Bank of India.</div></li>
                        </div>
                        <div className="cardDiv1">
                            <div className="listStyleCardDiv">
                                <div className="circle1">
                                    {/* <div className="point"></div> */}
                                </div>
                                <ul className="welcomeUnorderList1">
                                    Upload PDF's

                                </ul>
                            </div>
                            <li className="WelcomeList1"> <img src={greenCheck} className="greenCheck1" /> <div className="listText">Document must be in PDF format.</div></li>
                            <li className="WelcomeList1"><img src={greenCheck} className="greenCheck1" /><div className="listText">All document text must be clearly visible.</div></li>
                            <li className="WelcomeList1"><img src={greenCheck} className="greenCheck1" /> <div className="listText">Document must be attested by the bank.</div></li>

                        </div>
                    </div>

                    <div className="buttonDiv2">
                        <Button className="connectToCeplr-btn" onClick={handleShow}>

                            {loading ? <span className="spinner-border spinner-border-sm mr-1"></span> : "Proceed"}

                        </Button>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default WelcomeScreen
