import React from 'react'
// import MegaMenu from "../Navbar/MegaMenu";
import Hero from '../Hero'
import Cards from './cards/Cards'
import UseCases from '../UseCases'




function Home (){
    return (
        <>
       <Hero/>
       <Cards/>
       <UseCases/>
       
        </>
    )
}

export default Home