import React,{useState} from 'react'
import  './UseCasesLink.css'
import Button from '@restart/ui/esm/Button'

import {useForm} from 'react-hook-form'
import emailjs  from 'emailjs-com'
// import { BaseUrl, RedirectUrl } from 'Config/BaseUrl';



//Images
import heroImage from "../../images/hero-02.svg"
import whyCeplrBg from '../../images/why ceplr BG (2).svg'
import whyCeplr from '../../images/Frame.svg'
import getStartedBg from '../../images/get started BG.svg'
import getStartedUg from '../../images/get started image.svg'
import privacy from '../../images/privacy 1.png'
import backgroundImage from '../../images/Mask Group.svg'
import blueArrow from '../../images/blueArrow.png'
import whiteArrow from '../../images/whiteUseArrow.svg'
import useCaseBanner from '../../images/use case banner-05-09 1.svg'
import useCase1 from '../../images/Credit & Landing-12.svg'
import useCase2 from '../../images/mainUseCase2.svg'
import useCase3 from '../../images/mainUseCase3.svg'
import { BaseUrl } from 'Config/BaseUrl'

type profile = {
    Name: string;
    Email: string;
    Mobile: number;
  CompanyName:string;
  Message:string
  };



function UseCasesLinks() {
  const [result,showResult]=useState(false)
  const [submit,setSubmit ]  = useState(false);


  const Result=()=>{
    return(
      <div className="resultClass">Your message has been forwarded to our customer support team, who will contact you shortly. Thank you!</div>
    )
  }
  const ErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }
  const MobileErrorMessage=({message}:any)=>{
    return(
      <div className={window.innerWidth>920?"mobileError":"error"}>{message}</div>
    )
   
  }
      
    const {register,handleSubmit,formState: { errors },reset}=useForm<profile>(
      {mode:"onTouched"}
    )
    
      const onSubmit=handleSubmit(async(data:any)=>{
        setSubmit(true)
        // console.log(data, "data")
        const requestOptions = {
          method: 'POST',
          
          headers: { 
              // "authorization": ' xxxxxxxxxx' ,
  
            
              // "Content-Type":"application/json",
                    //  "x-api-key": "qjDm80GP4e6iK3L9rO0zF9uhhSHlMJfv47IkuSrU"
                   
                     
      },
      
           body: JSON.stringify({name:data.Name,email:data.Email,companyName:data.CompanyName,mobileNumber:data.Mobile,description:data.Message}),
  
           
      };
      // console.log(requestOptions, "requestOptions")
      try{
          await fetch(`https://${BaseUrl()}.ceplr.com/sendMail`, requestOptions)
          .then(response => response.json())
          .then(res => {
            //  console.log(res,"res")
             reset()
 setSubmit(false)
 showResult(true)
              
          })
  
              // console.log('The link was clicked.');
              
          
      }catch (error) {
          console.log(error, "error");
        }
      // axios.post('/api/form').then((response:any)=>{
      //   if (response.data.status === 'success'){
      //       alert("Message Sent."); 
      //       // console.log(response,"response")
      //       reset()
      //   }else if(response.data.status === 'fail'){
      //       alert("Message failed to send.")
      //   }
      // })
      
      // reset()
      // }
      
      // ).catch(err=>console.log(err))
      })
    
     //hideResult
  // setTimeout(()=>{
  //   showResult(false)
  // },5000)




  
    return (
        <>
        <section id="UseCasesPage">
        {/* <img src={backgroundImage}/> */}
        <div className=" container ">
        {/* <img src={useCaseBanner} style={{resize:"both"}}/> */}
                <div className="row align-items center justify-content center ">
                
                {/* <h1> One API, A Multitude <br/>
Of Use Cases</h1> */}
               
                </div>
            </div>
        </section>
            <section id ="Section1">
            {/* <img src={whyCeplrBg} className="whyCeplrBG"/> */}
        <div className="container">
        <div className="row align-items center ">
            <div className=" col col-sm-12 col-xl-6">
                   <div className="mfi-Container">

                       <div className="mfiHeading-Ceplr">CREDIT & LENDING</div>
                       <div className="mfiBigText">
                       Accounts statements   </div>
                       <div className="mfi-smallText1">
                       
                       Get authentic fraud-proof digital bank statements to empower your lending solution, while giving your customers a seamless experience.

                       </div>
                       <div className="mfiBigText">
                       Account  verification            </div>
                       <div className="mfi-smallText1">
                       
                       Verify your customer’s account details and confirm ownership in seconds. It's time to say goodbye to penny drops.

                       </div>
                       {/* <div className="mfi-smallText1">
                       
                       Verify your customer’s account details and ownership in seconds using their bank app.


                       </div> */}
                       <div className="mfiBigText">
                       Income verification               </div>
                       <div className="mfi-smallText1">
                       
                       Get insights into your customer's true income, powered by our proprietary transaction data analytics.
                       </div>
                       <div className="mfiBigText">
                       Combat fraud               </div>
                       <div className="mfi-smallText1">
                       
                       Fetch authenticate account data directly from the banks, slashing any instances of fraud.
                       </div>
                       <div className="mfiBigText">
                       Identity verification               </div>
                       <div className="mfi-smallText1">
                       
                       Verify your customer's personal details against the KYC records maintained by their bank.
                       </div>
                       {/* <div className="whyCeplr-smallText2">
                       We provide comprehensive documentation, developer guides, sample code, sample data, and everything you need to get up and running as fast as possible.
                       </div> */}
 <button className="getInTouch-btn" onClick={()=>{window.location.href="/useCase/#GetStarted"}}>
                            
                            Get in touch
                            <img src={whiteArrow} style={{height:"11px",marginLeft:"9px",marginTop:"-3px"}}/>
                        </button>
           
                   </div>
            </div>
            <div className="col col-sm-12 col-xl-6 ">
            <div className="mfiHeading-Ceplr2">CREDIT & LENDING</div>
                <img src={useCase1} className="mfi-Image"/>
                </div>
           
        </div>
        
        </div>
            </section>
            <section id ="Section2">
            {/* <img src={whyCeplrBg} className="whyCeplrBG"/> */}
            <div className="container">
        <div className="row align-items center ">
            <div className=" col col-sm-12 col-xl-6">
            <div className="mfiHeading-Ceplr2">PERSONAL FINANCE</div>
            <img src={useCase2} className="mfi-Image1"/>
               
            </div>
            <div className="col col-sm-12 col-xl-6 ">
            <div className="mfi-Container1">

<div className="mfiHeading-Ceplr">PERSONAL FINANCE </div>
<div className="mfiBigText1">
Real-time access to financial data                       </div>
<div className="mfi-smallText11">

Seamless on-demand access to transaction data helps you build smarter solutions for your users.


</div>
<div className="mfiBigText1">
Faster onboarding                       </div>
<div className="mfi-smallText11">

Link accounts and get customer account data instantly, without manual document uploads. A small step for a user, a giant leap for user experience.



</div>
<div className="mfiBigText1">
Standard data format, no matter the source                       </div>
<div className="mfi-smallText11">

Get account statements and transaction data from any bank in a single standard digital format.


</div>
<div className="mfiBigText1">
Insights and income tracking                       </div>
<div className="mfi-smallText11">

Our analytics layer over raw transaction data provides access to smartly categorized data, income verification, credit risk assessment and more.

</div>
{/* <div className="whyCeplr-smallText2">
We provide comprehensive documentation, developer guides, sample code, sample data, and everything you need to get up and running as fast as possible.
</div> */}
<button className="getInTouch-btn1" onClick={()=>{window.location.href="/useCase/#GetStarted"}}>
     
     Get in touch
     <img src={whiteArrow} className="bluearrow"/>
 </button>

</div>
                </div>
           
        </div>
        
        </div>
            </section>
            <section id ="Section3">
            {/* <img src={whyCeplrBg} className="whyCeplrBG"/> */}
            <div className="container">
        <div className="row align-items center ">
            <div className=" col col-sm-12 col-xl-6">
                   <div className="mfi-Container">

                       <div className="mfiHeading-Ceplr">INSURANCE </div>
                       <div className="mfiBigText">
                       Account verification   
           </div>
                       <div className="mfi-smallText1">
                       
                       Supercharge your onboarding process with instant customer identity and bank account verification.



                       </div>
                       <div className="mfiBigText">
                       Statements and income verification      </div>
                       <div className="mfi-smallText1">
                       
                       Power your insurance premium calculations with not just seamless access to bank statements, but also smart analytics with income verification.

                       </div>
                       <div className="mfiBigText">
                       Tamper-proof data      </div>
                       <div className="mfi-smallText1">
                       
                       Shield yourself from fraudulent statements with secure encrypted data fetched directly from banks.
                       </div>
                     
                       {/* <div className="whyCeplr-smallText2">
                       We provide comprehensive documentation, developer guides, sample code, sample data, and everything you need to get up and running as fast as possible.
                       </div> */}
 <button className="getInTouch-btn" onClick={()=>{window.location.href="/useCase/#GetStarted"}} >
                            
                            Get in touch
                            <img src={whiteArrow} style={{height:"11px",marginLeft:"9px",marginTop:"-3px"}}/>
                        </button>
           
                   </div>
            </div>
            <div className="col col-sm-12 col-xl-6 ">
            <div className="mfiHeading-Ceplr2">INSURANCE</div>
              
                <img src={useCase3} className="mfi-Image"/>
                </div>
           
        </div>
        
        </div>
            </section>
            <section id="GetStarted">
        {/* <img src={getStartedBg} className="getStartedBg"/> */}
           <div className="container">
           <div className="row align-items center">
                    <div className="col-sm-12 col-xl-6">
                    <img src={getStartedUg} className="getstarted-Original"/>
                        {/* <p>Get Started</p> */}
                      
                       
               </div>
               <div className="col-sm-12 col-xl-6">
                  
               {window.innerWidth>920? <form id="contact-form" className="contactWeb-formContainer" onSubmit={onSubmit}>
                       <h2>Ready to get started?</h2>
                       <p>Our experts can help you with your queries or show you a demo of our products.</p>
        
        <div className="firstRowInput">
          <div className="input1">
        <input  className="contactUs-text-input1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

{errors.Name && errors.Name.type === "required" && (
  <div className="mobileError">Name is required.</div>
)}
</div>
<div className="input2">
 <input  className="contactUs-text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

{errors.CompanyName && errors.CompanyName.type === "required" && (
  <div className="mobileError">Company name is required.</div>
)}
</div>
        </div>
        <div className="firstRowInput1">
        <div className="input1">
        <input  className="contactUs-text-input1" autoCapitalize='none' placeholder="Company Email " {...register("Email",{required:"Company email is required.",
pattern:{
 value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
 message:"Please enter a valid email."

}
       })}/>

       {errors.Email && (
         <ErrorMessage message={errors.Email.message}/>        )}
         </div>

         <div className="input2">
        <input  className="contactUs-text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
       </div>
       
        </div>

        <div className="secondRowInput">
        <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />

       
        </div>

        <div >
          <div className="priv-divContact">
          <img src={privacy} className="privacy-image"/>
           
           {"  " }    Your privacy is important to us. 
          </div>
        <button className="contactUsPage-Button">
          {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}
          Submit
        </button>
        <div>{result?<Result/>:null}</div>

        </div>
      </form>:<form id="contact-form" className="contact-formContainerMobileFirst" onSubmit={onSubmit}>
                        <h2>Ready to get started?</h2>
                        <p>Our experts can help you with your queries or show you a demo of our products.</p>
         <input  className="text-Contactinput1" placeholder="Name" type="text" {...register("Name",{required:true})}/>

        {errors.Name && errors.Name.type === "required" && (
          <div className="error">Name is required.
          </div>
        )}
         <input  className="text-input2"  placeholder="Company Name" {...register("CompanyName",{required:true})}/>

        {errors.CompanyName && errors.CompanyName.type === "required" && (
          <div className="error">Company name is required.</div>
        )}
         <input  className="text-input2" autoCapitalize="none" placeholder="Company Email " {...register("Email",{required:"Company email is required.",
pattern:{
  value:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message:"Please enter a valid email."

}
        })}/>

        {errors.Email && (
          <ErrorMessage message={errors.Email.message}/>        )}
         <input  className="text-input2" placeholder="Mobile Number" type="tel" {...register("Mobile",{required:"Mobile number is required.",
pattern:{
 value:/^[0]?[6789]\d{9}$/,
 message:"Please enter a valid mobile number."

}
       })}/>

       {errors.Mobile && (
         <MobileErrorMessage message={errors.Mobile.message}/>        )}
         <div >
         <textarea  className="messageInput"  placeholder="Write a message (Optional) " {...register("Message")} />
           <div className="priv-div">
           <img src={privacy} className="privacy-image"/>
            
            {"  " }    Your privacy is important to us. 
           </div>
         <button className="contact-usButton">
         {submit?<span className="spinner-border spinner-border-sm mr-1"></span>:null}

           Submit
         </button>
         <div>{result?<Result/>:null}</div>
         </div>
       </form>}
                    </div>
               </div>
           </div>
           
        </section>
        </>
    )
}

export default UseCasesLinks
