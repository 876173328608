import React from 'react'

import './ConsentFooter.css'

function ConsentFooter() {
    return (
        <section id="ConsentFooter">
            <div className="welcomeFooter">Hello</div>
        </section>
    )
}

export default ConsentFooter
